import Swiper from 'swiper/swiper-bundle.min';
import anime from 'animejs';

export function index(){
	const fvslide = document.getElementById('indexswipe');
	if(fvslide){
        new Swiper('#indexswipe', {
            spaceBetween: 0,
            effect: 'fade',
            speed: 2000,
			loop: true,
			simulateTouch: false,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
        }); 
	}
};